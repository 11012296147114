import React from "react";
import StarAdd from "./components/StarAdd";
import Text from "./components/Text";
import BaseWheel from "./components/BaseWheel";
import GoodsModel from "./components/GoodsModel";
import InputNumberModel from "./components/InputNumberModel";
import "./index.less";
import Http from "../../utils/Http";
import { message, Spin, Popover } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import AdvanceWheel from "./components/AdvanceWheel";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "../../store/app";
import { useNavigate } from "react-router-dom";
import MiddleWheel from "./components/MiddleWheel";
import { log } from "@craco/craco/lib/logger";
const welcomes = require("../Wheel/components/MiddleWheel/assets/alterWheel/top_text@3x.png");
const welcome = require("../../assets/motianlun/headerText.png");
const btn_zp_menu = require("../../assets/btn_zp_menu@3x.png");
const go1 = require("../../assets/motianlun/lowGo.png");
const go2 = require("../../assets/motianlun/centerGo.png");
const go3 = require("../../assets/motianlun/highGo.png");
const bg_zp_chuji_5 = require("../../assets/motianlun/lowHome.png");
const bg_zp_chuji_6 = require("../../assets/motianlun/centerHome.png");
const bg_zp_chuji_7 = require("../../assets/motianlun/highHome.png");

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Wheel = () => {
  const tabs = ["初级", "中级", "高级"];

  console.log("Wheel render");

  const paramsStr = window.location.search;
  const params = new URLSearchParams(paramsStr);

  const navigate = useNavigate();
  const storeToken = useSelector((state) => state.App.token);
  const dispatch = useDispatch();

  const token = params.get("token");
  const myLucky = React.useRef(null);
  const goodsModelRef = React.useRef(null);
  const inputNumberModelRef = React.useRef(null);
  const [index, setIndex] = React.useState(0);
  const [selectState, setSelectState] = React.useState({
    mode: 1,
    count: 1,
  });

  const [disable, setDisable] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [initLoading, setInitLoading] = React.useState(true);

  const [data, setData] = React.useState([]);
  const [targetData, setTargetData] = React.useState({
    count: 0,
    data: [],
  });
  const [count, setCount] = React.useState(null);
  const [customCount, setCustomCount] = React.useState(66);
  const [shengDou, setShengDou] = React.useState(null);
  const [record, setRecord] = React.useState([]);
  const [convert, setConvert] = React.useState(true);

  const error = React.useRef(false);
  //移除组件旋转
  const [isRotating, setIsRotating] = React.useState(true);
  const price = data[index]?.price ?? 0;
  const showGiftModel = () => {
    if (error.current) {
      setDisable(false);
      return;
    }

    goodsModelRef.current.show();
    setDisable(false);
    // setIsRotating(true);
  };

  const returnConvert = () => {
    return convert;
  };

  const findIndex = (goods_info) => {
    const productList = data[index]?.product_list ?? [];
    const target = productList?.findIndex(
      (item) => item?.product_info?.goods_info?.id === goods_info?.id
    );
    return target;
  };

  const getData = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/turntable/list",
        {
          category: 1,
        },
        undefined
      );
      if (res?.status === 10000) {
        setData(res?.data);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
    }
  };

  const getWalletList = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/user/walletList",
        {
          category: 1,
        },
        undefined
      );

      if (res?.status === 10000) {
        const wallet = res?.data?.find((item) => item?.asset === "jifen");
        const shengDouWallet = res?.data?.find(
          (item) => item?.asset === "shengdou"
        );

        setCount(wallet?.free_number ?? 0);
        setShengDou(shengDouWallet?.free_number ?? 0);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
    }
  };

  const getTurntableRes = async () => {
    try {
      const res = await Http.postForm("/api/v1/turntable/turntableRes", {
        pers: 10,
        page: 1,
      });

      if (res?.status === 10000) {
        setRecord(res?.data);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
    }
  };

  const lottery = async (id, num) => {
    if (loading) {
      return;
    }
    setConvert(false);
    let sendConvert = true;
    if (!document.getElementById("convertSwitch")) {
      sendConvert = true;
    } else {
      sendConvert = document.getElementById("convertSwitch").ariaChecked;
    }
    setLoading(true);
    // setIsRotating(false);

    myLucky.current.loading();
    try {
      const res = await Http.postForm("/api/v1/turntable/lottery", {
        turntable_id: id,
        count: num,
        convert: sendConvert,
      });
      if (res?.status === 10000) {
        error.current = false;
        myLucky.current.play(num, res?.data);
        setTargetData({
          count: num,
          data: res?.data,
        });
        setDisable(true);
        getWalletList();
      } else {
        error.current = true;
        // setTimeout(() => {
        myLucky.current.stop();
        // }, 5000);

        message.error(res?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (token && token !== storeToken) {
      dispatch(setToken(token));
    }
  }, [dispatch, storeToken, token]);

  const init = async () => {
    try {
      await Promise.all([getData(), getTurntableRes(), getWalletList()]);
    } catch (error) {
    } finally {
      setInitLoading(false);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    setTargetData({
      count: 0,
      data: [],
    });
  }, [index]);

  const renderTabItem = (item, _index) => {
    const isSelected = index === _index;

    const className = () => {
      if (isSelected && _index === 0) {
        return "tab-item-left-active";
      } else if (!isSelected && _index === 0) {
        return "tab-item-left";
      } else if (isSelected && _index === 1) {
        return "tab-item-middle-active";
      } else if (!isSelected && _index === 1) {
        return "tab-item-middle";
      } else if (isSelected && _index === 2) {
        return "tab-item-right-active";
      } else if (!isSelected && _index === 2) {
        return "tab-item-right";
      }
    };

    return (
      <div
        className={className()}
        key={_index}
        onClick={() => {
          if (disable) {
            return;
          }

          setIndex(_index);
        }}
      >
        {item}
      </div>
    );
  };

  if (initLoading) {
    return (
      <div className="load-container">
        <div className="loading">
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        index === 0
          ? "wheel-container wheel-container-base"
          : index === 1
            ? "wheel-container wheel-container-middle"
            : "wheel-container wheel-container-advanced"
      }
    >
      <GoodsModel
        ref={goodsModelRef}
        targetData={targetData}
        setTargetData={setTargetData}
        lottery={lottery}
        index={index}
        data={data}
        productList={data[index]?.product_list ?? []}
      />
      <InputNumberModel
        ref={inputNumberModelRef}
        lottery={lottery}
        index={index}
        data={data}
        onChange={(value) => {
          setCustomCount(value);
        }}
      />
      <div className="more-container">
        <div className="more-welcome">
          {/* {index === 1 ? (
            <img src={welcomes} alt="" />
          ) : ( */}
          <img src={welcome} alt="" />
          {/* )} */}
        </div>

        <div className="wheel-more">
          <Popover
            placement="topLeft"
            title={null}
            content={
              <div>
                <div
                  className="more-item"
                  onClick={() => {
                    navigate("/dazhuanpan/activity/description");
                  }}
                >
                  规则说明
                </div>
                {/* <div
                  className="more-item"
                  onClick={() => {
                    navigate("/dazhuanpan/ranking/list");
                  }}
                >
                  排行榜单
                </div> */}
                <div
                  className="more-item"
                  onClick={() => {
                    navigate("/dazhuanpan/winning/record");
                  }}
                >
                  中奖记录
                </div>
              </div>
            }
            trigger="click"
          >
            <img src={btn_zp_menu} alt="" />
          </Popover>
        </div>
      </div>
      <div className="tab-container">{tabs.map(renderTabItem)}</div>
      <div className="star-add-container">
        <StarAdd
          count={count}
          shengDou={shengDou}
          onChange={(data) => {
            console.log(1);
            console.log(data);
            console.log(2);
            setConvert(data);
          }}
          getWalletList={getWalletList}
        />
      </div>
      <div className="text-container">
        <Text record={record} />
      </div>
      <div className="lucky-wheel-container ">
        <div className={isRotating ? "rotate" : " "}>
          {index === 0 ? (
            <BaseWheel
              id={data[0]?.id}
              productList={data[0]?.product_list ?? []}
              ref={myLucky}
              findIndex={findIndex}
              targetData={targetData}
              setTargetData={setTargetData}
              showGiftModel={showGiftModel}
              lottery={lottery}
              selectState={selectState}
            />
          ) : index === 1 ? (
            <MiddleWheel
              id={data[1]?.id}
              productList={data[1]?.product_list ?? []}
              ref={myLucky}
              findIndex={findIndex}
              targetData={targetData}
              setTargetData={setTargetData}
              showGiftModel={showGiftModel}
              lottery={lottery}
              selectState={selectState}
            />
          ) : (
            <AdvanceWheel
              id={data[2]?.id}
              productList={data[2]?.product_list ?? []}
              ref={myLucky}
              findIndex={findIndex}
              targetData={targetData}
              setTargetData={setTargetData}
              showGiftModel={showGiftModel}
              lottery={lottery}
              selectState={selectState}
            />
          )}
        </div>
      </div>
      <div className="house_out">
        <div className="house_box">
          {index === 0 ? (
            <img src={bg_zp_chuji_5}></img>
          ) : index === 1 ? (
            <img src={bg_zp_chuji_6}></img>
          ) : (
            <img src={bg_zp_chuji_7}></img>
          )}
        </div>
        <div className="house_center">
          {index === 0 ? (
            <img
              onClick={() => {
                myLucky.current.onStart();
              }}
              src={go1}
            ></img>
          ) : index === 1 ? (
            <img
              onClick={() => {
                myLucky.current.onStart();
              }}
              src={go2}
            ></img>
          ) : (
            <img
              onClick={() => {
                myLucky.current.onStart();
              }}
              src={go3}
            ></img>
          )}
        </div>
      </div>

      {index === 0 ? (
        <div className="buttons">
          <div
            className={selectState.mode === 1 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 1,
                count: 1,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽1次</div>
              <div className="label">{price}积分/次</div>
            </div>
          </div>
          <div
            className={selectState.mode === 2 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 2,
                count: 10,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽10次</div>
              <div className="label">{price * 10}积分/次</div>
            </div>
          </div>
          <div
            className={selectState.mode === 3 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 3,
                count: customCount,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽{customCount}次</div>
              <div className="label">{price * customCount}积分/次</div>
            </div>
          </div>
        </div>
      ) : index === 1 ? (
        <div className="buttons1">
          <div
            className={selectState.mode === 1 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 1,
                count: 1,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽1次</div>
              <div className="label">{price}积分/次</div>
            </div>
          </div>
          <div
            className={selectState.mode === 2 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 2,
                count: 10,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽10次</div>
              <div className="label">{price * 10}积分/次</div>
            </div>
          </div>
          <div
            className={selectState.mode === 3 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 3,
                count: customCount,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽{customCount}次</div>
              <div className="label">{price * customCount}积分/次</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="buttons">
          <div
            className={
              selectState.mode === 1
                ? "button-advanced"
                : "disable-button-advanced"
            }
            onClick={() => {
              setSelectState({
                mode: 1,
                count: 1,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽1次</div>
              <div className="label">{price}积分/次</div>
            </div>
          </div>
          <div
            className={
              selectState.mode === 2
                ? "button-advanced"
                : "disable-button-advanced"
            }
            onClick={() => {
              setSelectState({
                mode: 2,
                count: 10,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽10次</div>
              <div className="label">{price * 10}积分/次</div>
            </div>
          </div>
          <div
            className={
              selectState.mode === 3
                ? "button-advanced"
                : "disable-button-advanced"
            }
            onClick={() => {
              setSelectState({
                mode: 3,
                count: customCount,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽{customCount}次</div>
              <div className="label">{price * customCount}积分/次</div>
            </div>
          </div>
        </div>
      )}

      <div
        className={"custom"}
        onClick={() => {
          inputNumberModelRef.current.show();
        }}
      >
        <div>自定义次数</div>
      </div>
    </div>
  );
};

export default Wheel;
