import React from 'react';
import './index.less';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'antd';
import { useNavigate } from 'react-router-dom';
import Http from '../../utils/Http';
import { message } from 'antd';
import moment from 'moment';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const btn_nav_black = require('../../assets/btn_nav_black@3x.png');
const bg_zp_bangdan = require('./assets/bg_zp_bangdan@3x.png');
const img_leaderboard_01 = require('./assets/img_leaderboard_01@3x.png');
const img_leaderboard_02 = require('./assets/img_leaderboard_02@3x.png');
const img_leaderboard_03 = require('./assets/img_leaderboard_03@3x.png');
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const switchIndex = (index) => {
  if (index === 0) {
    return [true, img_leaderboard_01, '#FFBB10'];
  } else if (index === 1) {
    return [true, img_leaderboard_02, '#A6CDFD'];
  } else if (index === 2) {
    return [true, img_leaderboard_03, '#F3CFA1'];
  } else {
    return [false, undefined, '#FFFFFF'];
  }
};

const Item = (props) => {
  const { item, index } = props;

  const [show, background, fontColor] = switchIndex(index);

  const showScore = (num) => {
    if (num < 10000) {
      return num;
    } else {
      return num + 'w';
    }
  };

  return (
    <div>
      <div className='ranking-list-item'>
        <div
          className='ranking-list-item-rank'
          style={{
            color: fontColor,
          }}
        >
          {index + 1}
        </div>
        <div className='ranking-list-item-left'>
          {show && (
            <img
              src={background}
              style={{
                height: 54,
                width: 44,
              }}
              alt=''
            />
          )}
          <div
            style={{ position: show ? 'absolute' : 'relative' }}
            className={show ? 'ranking-list-item-avatar' : ''}
          >
            <img
              style={{
                height: 36,
                width: 36,
                borderRadius: 18,
              }}
              alt=''
              src={item?.user_info?.avatar}
            />
          </div>
          <div className='ranking-list-item-name'>
            {item?.user_info?.nickname}
          </div>
        </div>
        <div className='ranking-list-item-right'>{showScore(item?.score)}</div>
      </div>
    </div>
  );
};

const RankingList = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [userInfo, setUserInfo] = React.useState(null);

  const [isShow, setIsShow] = React.useState(null);
  const [score, setScore] = React.useState(0);

  const navigate = useNavigate();

  const loadMoreData = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const res = await Http.postForm('/api/v1/turntable/rankList');

      if (res.status === 10000) {
        setUserInfo(res?.data?.user_info);
        setData(res?.data?.list);
        setIsShow(res?.data?.user_ranking);
        setScore(res?.data?.score);
      } else {
        message.message(res?.message);
      }
    } catch (error) {
      message.message('网络错误，请检查网络设置');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadMoreData();
  }, []);

  return (
    <div className='ranking-list'>
      <div className='ranking-list-text'>
        <img
          src={bg_zp_bangdan}
          style={{
            width: 88,
            height: 31,
          }}
          alt=''
        />
      </div>
      <div
        className='ranking-list-close'
        onClick={() => {
          navigate('/dazhuanpan');
        }}
      >
        <img
          src={btn_nav_black}
          style={{
            width: 24,
            height: 24,
          }}
          alt=''
        />
      </div>

      {userInfo ? (
        <>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
            id='scrollableDiv'
          >
            {data.length > 0 ? (
              <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={false}
                scrollableTarget='scrollableDiv'
              >
                <List
                  dataSource={data}
                  renderItem={(item, index) => (
                    <Item key={index} item={item} index={index} />
                  )}
                />
              </InfiniteScroll>
            ) : (
              <div className='ranking-list-empty'>暂无数据</div>
            )}
          </div>
          <div className='ranking-list-bottom'>
            <div className='ranking-list-bottom-avatar'>
              <img
                alt=''
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                }}
                src={userInfo?.avatar}
              />
            </div>
            <div className='ranking-list-bottom-flex'>
              <div className='ranking-list-bottom-flex-name'>
                {userInfo?.nickname}
              </div>
              <div className='ranking-list-bottom-flex-count'>{score}</div>
            </div>
            <div className='ranking-list-bottom-text'>
              {isShow ? isShow : '未上榜'}
            </div>
          </div>
        </>
      ) : (
        <div className='load-container'>
          <div className='loading'>
            <Spin indicator={antIcon} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RankingList;
