import React from "react";
import "./index.less";
import InfiniteScroll from "react-infinite-scroll-component";
import { List } from "antd";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import { message } from "antd";
import moment from "moment";

const btn_nav_black = require("../../assets/btn_nav_black@3x.png");
const bg_zp_zhongjiang = require("./assets/bg_zp_zhongjiang@3x.png");

const Item = (props) => {
  const { item } = props;
  return (
    <div className="winning-record-item">
      <div className="winning-record-item-left">
        <div className="winning-record-item-container">
          <div className="winning-record-item-img">
            <img
              style={{
                width: 20,
                height: 20,
              }}
              src={item?.goods_info?.icon_path}
              alt=""
            />
          </div>
          <div className="winning-record-item-name">
            <span
              style={{
                fontSize: 15,
                color: "white",
              }}
            >
              {item?.goods_info?.name}{" "}
            </span>
            <span
              style={{
                fontSize: 15,
                color: "#F5DB49",
              }}
            >
              *{item?.count}
            </span>
          </div>
        </div>
        <div className="winning-record-item-description">
          {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      </div>
      <div className="winning-record-item-right">
        {item?.goods_info?.sale_price}声豆
      </div>
    </div>
  );
};

const WinningRecord = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [pers, setPers] = React.useState(10);
  const [hasMore, setHaveMore] = React.useState(true);

  const loadMoreData = async () => {
    if (loading) {
      return;
    }

    if (!hasMore) {
      return;
    }

    setLoading(true);
    try {
      const res = await Http.postForm("/api/v1/turntable/lotteryRes", {
        page: page,
        pers: pers,
        category:0,
      });

      if (res.status === 10000) {
        setData(data.concat(res?.data));

        if (!res?.has_next) {
          setHaveMore(false);
        }

        setPage(page + 1);
      } else {
        message.message(res?.message);
      }
    } catch (error) {
      message.message("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();

  React.useEffect(() => {
    loadMoreData();
  }, []);

  return (
    <div className="winning-record">
      <div className="winning-record-text">
        <img
          src={bg_zp_zhongjiang}
          style={{
            width: 88,
            height: 31,
          }}
          alt=""
        />
      </div>
      <div className="winning-record-close">
        <img
          src={btn_nav_black}
          style={{
            width: 24,
            height: 24,
          }}
          alt=""
          onClick={() => {
            navigate("/dazhuanpan");
          }}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
        id="scrollableDiv"
      >
        {data.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={loadMoreData}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={data}
              renderItem={(item) => <Item key={item.id} item={item} />}
            />
          </InfiniteScroll>
        ) : (
          <div className="winning-record-empty">暂无数据</div>
        )}
      </div>
    </div>
  );
};

export default WinningRecord;
