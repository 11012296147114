import React from 'react';
import { Drawer, message, Switch } from 'antd';
import Http from '../../../../utils/Http';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { sendPostMessage } from '../../../../utils/message';
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const btn_main_chat_add = require('../../../../assets/btn_main_chat_add@3x.png');
const icon_main_wallet_jifen = require('../../../../assets/icon_main_wallet_jifen@3x.png');
const sys_btn_delete_list = require('../../../../assets/sys_btn_delete_list@3x.png');
const icon_main_wallet_shendou = require('../../../../assets/icon_main_wallet_shendou@3x.png');

const StarAdd = (props) => {
  const { count, shengDou, getWalletList } = props;

  const [visible, setVisible] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [select, setSelect] = React.useState(null);

  const [data, setData] = React.useState([]);
  const [info, setInfo] = React.useState({});



  const getData = async () => {
    try {
      const res = await Http.postForm('/api/v1/product/turntableProduct');

      if (res?.status === 10000) {
        // setInfo({
        //   goods_info: res?.data?.goods_info,
        //   asset_info: res?.data?.asset_info,
        // });
        setData(res?.data);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error('网络错误，请检查网络设置');
    }
  };

  const buy = async () => {
    if (loading) {
      return;
    }
    if (!select) {
      message.success('请先选择套餐');

      return;
    }

    setLoading(true);

    try {
      const res = await Http.postForm('/api/v1/product/buy', {
        sku_id: select?.skuList[0]?.id,
        amount: 1,
      });

      if (res?.status === 10000) {
        message.success('操作成功');

        getWalletList && getWalletList();
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error('网络错误，请检查网络设置');
    } finally {
      setLoading(false);
    }
  };

  const renderItem = (item) => {
    const isSelected = select?.id === item?.id;
    return (
      <div
        key={item?.id}
        className={
          isSelected
            ? 'jinfen-item-container jinfen-item-active'
            : 'jinfen-item-container'
        }
        onClick={() => {
          setSelect(item);
        }}
      >
        <img
          src={item?.goods_info?.icon_path}
          alt=''
          style={{
            width: 50,
            height: 50,
          }}
        />
        <div className='jinfen-item-price-text'>
          *{item?.skuList[0]?.effective_time}天
        </div>
        <div className='jinfen-item-description-text'>
          赠{item?.skuList[0]?.send_amount}积分
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        onClick={() => {
          setVisible(true);
        }}
      >
        <div className='star-wrapper'>
          <img
            src={icon_main_wallet_jifen}
            style={{
              width: 20,
              height: 20,
            }}
            alt=''
          />
          <div className='star-count'>{count}</div>
          <img
            src={btn_main_chat_add}
            style={{
              width: 17,
              height: 17,
            }}
            alt=''
          />
        </div>
      </div>

      <Drawer
        visible={visible}
        placement={'bottom'}
        title={null}
        footer={null}
        closable={false}
        onClose={() => {
          setVisible(false);
        }}
        bodyStyle={{
          margin: 0,
          padding: 0,
          background: '#1D1928',
          borderTopRightRadius: 24,
          borderTopLeftRadius: 24,
          display: 'flex',
        }}
      >
        <div className='jinfen-container'>
          <div className='jinfen-title'>获得积分</div>
          <div
            className='jinfen-close'
            onClick={() => {
              setVisible(false);
            }}
          >
            <img
              src={sys_btn_delete_list}
              style={{
                width: 24,
                height: 24,
              }}
              alt=''
            />
          </div>

          <div className='jinfen-show-container'>
            <div className='jinfen-show-container-count-container'>
              <img
                src={icon_main_wallet_jifen}
                style={{
                  width: 20,
                  height: 20,
                }}
                alt=''
              />
              <div className='jinfen-show-container-count'>{count}</div>
              <div  style={{marginLeft: 8}}>
                <Switch checkedChildren="开启" id={"convertSwitch"} unCheckedChildren="关闭" onChange={props.onChange} defaultChecked />
              </div>
              <div style={{color: '#fff'}}>
                （自动兑换）
              </div>
            </div>
            <div
              className='shengdou-container'
              onClick={() => {
                let message = {
                  type: 'navigation',
                  params: {
                    name: 'RechargeSoundBeans',
                  },
                };

                sendPostMessage(JSON.stringify(message));
              }}
            >
              <img
                src={icon_main_wallet_shendou}
                style={{
                  width: 20,
                  height: 20,
                }}
                alt=''
              />
              <div className='shengdou-container-count'>{shengDou}</div>
              <img
                src={btn_main_chat_add}
                style={{
                  width: 20,
                  height: 20,
                }}
                alt=''
              />
            </div>
          </div>

          <div className='jinfen-get-container'>{data.map(renderItem)}</div>

          <div className='jinfen-button-container'>
            <div
              className='jinfen-button'
              onClick={() => {
                buy();
              }}
            >
              {loading && (
                <div
                  style={{
                    marginRight: 10,
                  }}
                >
                  <Spin indicator={antIcon} />
                </div>
              )}

              <div>支付 {select?.skuList[0]?.pay_amount ?? 0}声豆</div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default StarAdd;
