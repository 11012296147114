import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/virtual";
import { Autoplay } from "swiper";

const Text = (props) => {
  const { record } = props;

  console.log("record", record);

  return (
    <Swiper
      modules={[Autoplay]}
      loop
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      direction={"vertical"}
      className="mySwiper"
    >
      {record?.map((item) => {
        return (
          <SwiperSlide key={item?.id}>
            <div

              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div
                className="float-text"
                style={{
                  alignSelf: "center",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 11,
                    paddingTop: 6,
                  }}
                >
                  恭喜{" "}
                </span>
                <span
                  style={{
                    color: "#FFC938",
                    fontSize: 11,
                    paddingTop: 6,
                  }}
                >
                  {item?.user_info?.nickname}
                </span>
                <span
                  style={{
                    color: "white",
                    fontSize: 11,
                    paddingTop: 6,
                  }}
                >
                  {" "}
                  获得{" "}
                </span>
                <span
                  style={{
                    color: "#FFC938",
                    fontSize: 11,
                    paddingTop: 6,
                  }}
                >
                  {item?.goods_info?.name}x{item?.count}
                </span>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
  // return (
  //   <div>
  //     <span
  //       style={{
  //         color: "white",
  //         fontSize: 11,
  //       }}
  //     >
  //       恭喜{" "}
  //     </span>
  //     <span
  //       style={{
  //         color: "#FFC938",
  //         fontSize: 11,
  //       }}
  //     >
  //       Wa.无敌糖糖猫
  //     </span>
  //     <span
  //       style={{
  //         color: "white",
  //         fontSize: 11,
  //       }}
  //     >
  //       {" "}
  //       获得{" "}
  //     </span>
  //     <span
  //       style={{
  //         color: "#FFC938",
  //         fontSize: 11,
  //       }}
  //     >
  //       鲨鱼x1
  //     </span>
  //   </div>
  // );
};

export default Text;
